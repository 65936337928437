<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { reactiveComputed } from '@vueuse/core'
import emptyImg from '@/assets/imgs/empty.png'

const listQuerier = useQuery({
  queryKey: [apis.articleList.id, currentLocale],
  queryFn: () =>
    apis.articleList({
      type: 2,
      pos: 1,
      page: 1,
      page_size: 10,
    }),
})

const listData = computed(() => listQuerier?.data.value?.list)
</script>

<template>
  <div class="page-content page-content-width">
    <h1>
      <span>{{ $t("dTKmOOljwW8ju5qOEXBtv") }}</span>
    </h1>

    <ASpin :spinning="listQuerier.isLoading.value">
      <ul v-if="listData" class="list">
        <li
          v-for="(item, i) in listData"
          :key="i"
          class="divide-y divide-dashed divide-[#303C6A]"
        >
          <NuxtLinkLocale class="item" :to="`/announcement/detail?id=${item.article_id}`" data-test-id="nbNX">
            <h2 class="title">{{ currentLocale === "zh" ? item.title : item.title_en }}</h2>
            <time class="date">{{ $d(item.create_time * 1000 ?? 0, "date").replace(/\//g, "-") }}</time>
            <p class="brief">{{ currentLocale === "zh" ? item.summary : item.summary_en }}</p>
          </NuxtLinkLocale>
        </li>
      </ul>
    </ASpin>

    <div v-if="listQuerier.isSuccess.value && !listData?.length" class="empty-div">
      <AEmpty :description="$t('Lo7vgXm0_sydof8f-tdwH')" :image="emptyImg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media bp-lt-tabletl {
  .page-content {
    margin: 16px auto !important;
    border-radius: 16px !important;

    h1 {
      padding: 15px !important;
      font-size: 26px !important;
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
    }

    .list {
      padding: 0 16px 16px !important;
      overflow-y: scroll;

      .item {
        padding: 20px 0  10px !important;

        .title {
          font-size: 16px !important;
          font-weight: 500 !important;
        }
      }
    }

    .empty-div {
      :deep(.ant-empty-image) {
        height: 120px;
        opacity: 0.6;
      }

      :deep(.ant-empty-description) {
        font-size: 16px;
        color: var(--first-text);
      }
    }
  }
}

.page-content {
  box-sizing: border-box;
  margin: 40px auto;
  overflow: hidden;
  background: #202c52;
  border-radius: 30px;

  h1 {
    padding: 30px 40px;
    font-size: 30px;
    font-weight: bold;
    color: var(--first-text);
    background: linear-gradient(180deg, #2c3967 10%, #202c52 80%);

    span {
      position: relative;
      padding-left: 20px;

      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        width: 4px;
        height: 26px;
        content: '';
        background: var(--highlight);
      }
    }
  }

  .list {
    min-height: 600px;
    padding: 0 40px 40px;

    li:last-child .item{
      border: none;
    }

    .item {
      display: grid;
      grid-template-areas: 'title date' 'brief brief';
      grid-template-columns: 1fr auto;
      gap: 6px 30px;
      padding: 24px 0;
      border-bottom: 1.5px dashed #303c6a;

      .title {
        grid-area: title;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.4em;
        color: var(--first-text);
      }

      .date {
        grid-area: date;
        color: var(--secondary-text);
        text-align: right;
      }

      .brief {
        display: -webkit-box;
        grid-area: brief;
        overflow: hidden;
        font-size: 16px;
        line-height: 1.4em;
        color: var(--secondary-text);
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 设置行数 */
      }

      &:hover {
        .title {
          color: #266ef1;
        }
      }
    }
  }

  .empty-div {
    --uno: 'lt-mobile:min-h-500px';

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 800px;
    background: #202c52;

    :deep(.ant-empty-image) {
      --uno: 'lt-mobile:h-150px';

      height: 268px;
      margin-bottom: 20px;
    }

    :deep(.ant-empty-description) {
      font-size: 20px;
      color: var(--first-text);
    }
  }
}
</style>
